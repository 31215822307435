import { Column, Row } from "hedron";
import React from "react";
import { ContentColumn } from "../components/ContentColumn";
import Layout from "../components/Layout";
import PageContent from "../components/PageContent";
import SEO from "../components/seo";
import SideBar from "../components/SideBar";
import { useForm } from 'react-hook-form';
import { Box, CheckBox, TextInput } from "grommet";
import axios from "axios";
import { PageProps } from "gatsby";
import styled from "../lib/styled";
import { StaticImage } from "gatsby-plugin-image";

interface GiveAwayForm {
  name: string;
  email: string;
  dob: string;
  addressLine1: string;
  addressLine2?: string;
  town: string;
  state: string;
  country: string;
  zipcode: string;
}

const GiveAwayFormPage: React.FC<PageProps> = ({ location }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<GiveAwayForm>();
  const [submitError, setError] = React.useState("");
  const [result, setResult] = React.useState("");
  const [terms, setTerms] = React.useState(false);

  const onSubmit = React.useCallback((values) => {
    setError("");
    setResult("");
    axios.request({
      method: "POST",
      data: values,
      url: "https://o8for414g6.execute-api.us-east-1.amazonaws.com/form"
    }).then((value) => {
      setResult("Your entry has been succesfully recorded.")

    }).catch((error) => {
      setError(`There was an error processing your entry: ${error?.response?.data?.message || "Please try again."}`)
    })

  }, []);

  return (
    <Layout location={location}>
      <>
        <SEO
          title="Giveaway"
        />
        <Row>
          <ContentColumn md={9}>
            <StaticImage
              alt="Preorder now"
              layout="fullWidth"
              src="../images/free-egg-banner.jpeg"
            />
            <PageContent>
              <Box>
                <h1>Giveaway</h1>
                <Box>
                  <p>We aren't currently running any giveaways.</p>
                </Box>
                {/* {result ? (
                  <Box>
                    {result}
                  </Box>
                ) : (
                  <Box>
                    <p>Be one of the first 250 fans to receive a free egg from the latest Captivz Clash edition range!</p>
                    <form onSubmit={handleSubmit(onSubmit)}>

                      <h4>Your details</h4>
                      <Box pad={{ top: "10px" }}>
                        <Label for="name">Full name</Label>
                        <StyledTextInput required id="name" autocomplete="name" name="name" {...register("name")} />
                        {errors?.name?.message || ""}
                      </Box>
                      <Box pad={{ top: "10px" }}>
                        <Label for="email">Email address</Label>
                        <StyledTextInput required type="email" autocomplete="email" id="email" name="email" {...register("email")} />
                        {errors?.email?.message || ""}
                      </Box>
                      <Box pad={{ top: "10px" }}>
                        <Label for="dob">Date of birth</Label>
                        <StyledTextInput required id="dob" name="dob" autocomplete="bday" type="date" pattern="\d{4}-\d{2}-\d{2}" {...register("dob")} />
                        {errors?.dob?.message || ""}
                      </Box>
                      <Box pad={{ vertical: "2rem" }}>
                        <h4>Your address</h4>
                        <Box pad={{ top: "10px" }}>
                          <Label for="addressLine1">Address Line 1</Label>
                          <StyledTextInput required id="addressLine1" autocomplete="address-line1" name="addressLine1" {...register("addressLine1")} />
                          {errors?.addressLine1?.message || ""}
                        </Box>
                        <Box pad={{ top: "10px" }}>
                          <Label for="addressLine2">Address Line 2</Label>
                          <StyledTextInput id="addressLine2" autocomplete="address-line2" name="addressLine2" {...register("addressLine2")} />
                          {errors?.addressLine2?.message || ""}
                        </Box>
                        <Box pad={{ top: "10px" }}>
                          <Label for="town">Town</Label>
                          <StyledTextInput required id="town" autocomplete="address-level2" name="town" {...register("town")} />
                          {errors?.town?.message || ""}
                        </Box>
                        <Box pad={{ top: "10px" }}>
                          <Label for="town">State</Label>
                          <StyledTextInput required id="state" autocomplete="address-level1" name="state" {...register("state")} />
                          {errors?.state?.message || ""}
                        </Box>
                        <Box pad={{ top: "10px" }}>
                          <Label for="town">Country</Label>
                          <StyledTextInput required id="country" autocomplete="country-name" name="country" {...register("country")} />
                          {errors?.country?.message || ""}
                        </Box>
                        <Box pad={{ top: "10px" }}>
                          <Label for="zipcode">ZIP Code</Label>
                          <StyledTextInput required id="zipcode" name="zipcode" autocomplete="postal-code" type="text" pattern="[0-9]{5}" title="Zip code" {...register("zipcode")} />
                          {errors?.zipcode?.message || ""}
                        </Box>
                      </Box>
                      <Box pad={{ bottom: "10px" }} direction="row" align="center">
                        <Box pad={{ right: "10px" }}>
                          <CheckBox id="terms" name="terms" checked={terms} onChange={() => setTerms(!terms)} />
                        </Box>
                        <Label for="terms">To enter this giveaway, you must read and accept ToyMonster's <a href="https://jurassicoutpost.com/jurassic-outpost-giveaway-terms.pdf" target="_blank" rel="nofollow noreferrer">terms and conditions</a>. </Label>
                      </Box>
                      <Box pad={{ bottom: "10px" }} direction="row" align="center">
                        <p>All information submitted as part of this promotion will be deleted within 6 months after the final prize has been mailed/shipped to the winning entrants</p>
                      </Box>


                      <Button disabled={!terms} type="submit">
                        Submit form
                      </Button>
                      {submitError && (
                        <Box>
                          <p><strong>{submitError}</strong></p>
                        </Box>
                      )}
                    </form>
                  </Box>
                )} */}
              </Box>
            </PageContent>
          </ContentColumn>
          <Column md={3}>
            <SideBar />
          </Column>
        </Row>
      </>
    </Layout >
  );

};

const Label = styled.label`
  font-size: 14px;
  cursor: pointer;
  margin: 5px 0;
`;
const StyledTextInput = styled(TextInput)`
  font-weight: normal;
`;
const Button = styled.button`
  background: ${({ theme }) => theme.color.darkFour};
  color: white;
  outline: none;
  border: none;
  border-radius: 2px;
  padding: 8px 12px;
  cursor: pointer;
  font-family: "Keep Calm";
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 12px;
  :disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`

export default GiveAwayFormPage;

